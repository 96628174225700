import React from "react"

import * as Accordion from "@radix-ui/react-accordion"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SplashImage from "../images/Mock.png"

import LogoWall from "../components/LogoWall"

import CTA from "../components/cta"

import { Link } from "gatsby"
import MarketingSegmentation from "../images/Marketing_ Segmentation.svg"
import SalesAlerts from "../images/sales-alerts.svg"
import RevOps1 from "../images/use-cases/revops 1.png"
import RevOps3 from "../images/use-cases/revops 3.png"
import Sales2 from "../images/use-cases/sales 2.png"
import Sales3 from "../images/use-cases/sales 3.png"

const AccordionItem = ({ id, title, highlight, content }) => {
  return (
    <Accordion.Item value={id} className="py-4">
      <Accordion.Header>
        <Accordion.Trigger>
          <h4 className="text-3xl">{title}</h4>
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <p className="text-xl leading-relaxed text-medium-grey/75 py-4">
          <span className="text-lilac">{highlight} </span>&mdash; {content}
        </p>
      </Accordion.Content>
    </Accordion.Item>
  )
}

const Platform = () => {
  const teamPillClass =
    "rounded-lg px-3 py-1 text-medium-grey my-3 bg-white/10 border border-medium-grey hover:text-blue hover:border-blue hover:bg-blue/10 transition"

  return (
    <Layout variant="dataPoint">
      <SEO
        title="Go-to-market data automation"
        description="Deliver personalised buyer experiences and free up your data team from complicated queries with our go-to-market data automation."
      />

      <div className="container mx-auto py-8 md:py-16">
        <div
          className="flex flex-col gap-8 my-24 px-6 items-center text-center"
          id="hero-container"
        >
          <h1
            className="font-bold text-white text-5xl md:text-7xl max-w-7xl"
            style={{
              mixBlendMode: "overlay",
              fontFeatureSettings: "'ss02' on, 'ss03' on, 'ss01' on",
            }}
          >
            A data marketplace for your entire customer lifecycle.
          </h1>
          <h2
            className="text-white text-xl lg:text-2xl max-w-5xl"
            style={{ fontFeatureSettings: "'salt' on" }}
          >
            Deliver more personalised buyer experiences and free up your data team
            queries with our customisable data platform.
          </h2>
          <div className="flex flex-col lg:flex-row lg:gap-2 text-xl">
            <Link className={teamPillClass} to="/solutions#strategy">
              Strategy
            </Link>
            <Link className={teamPillClass} to="/solutions#marketing">
              Marketing
            </Link>
            <Link className={teamPillClass} to="/solutions#sales">
              Sales
            </Link>
            <Link className={teamPillClass} to="/solutions#customer-success">
              Customer Success
            </Link>
            <Link className={teamPillClass} to="/solutions#partnerships">
              Partnerships
            </Link>
            <Link
              className={teamPillClass}
              to="/solutions#operations-enablement"
            >
              Operations &amp; Enablement
            </Link>
          </div>

          <LogoWall />
        </div>
        <img src={SplashImage} />
        <section className="container text-white my-48">
          <div className="px-4 lg:px-0 lg:w-3/4">
            <h3 className="text-5xl lg:text-6xl leading-tight mb-8 font-semibold">
              Continuously optimise your targeting, timing and messaging.
            </h3>
            <p className="text-2xl lg:text-3xl leading-relaxed text-dark-grey">
              Increase your revenue efficiency with an end-to-end optimisation
              of your targeting, timing and messaging. Get more of the right
              data, in the right format in your CRM. Streamline manual steps,
              consolidate tools and reduce data costs.
            </p>
          </div>
        </section>

        {/* Strategy */}

        <section
          className="container text-white my-48 flex flex-col lg:flex-row px-4 lg:px-0 items-center gap-24"
          id="strategy"
        >
          <div className="flex-1">
            <h3 className="text-5xl leading-tight mb-16 font-semibold">
              Go-to-market strategy
            </h3>

            <Accordion.Root
              type="single"
              defaultValue="item-1"
              className="divide-y divide-dark-grey/50"
            >
              <AccordionItem
                id="item-1"
                title="Go-to-market Planning"
                highlight="Research every company and prospect to understand your addressable market."
                content="Understand opportunity size and addressable market. Score and prioritize each company. Automate internet and people research on every company in a market."
              />
              <AccordionItem
                id="item-2"
                title="New markets and verticals"
                highlight="Assess product market fit in new markets and verticals"
                content="Find, score and prioritise companies in new markets and verticals that fit your current ideal customer profile. Understand which markets and verticals to expand into."
              />
              <AccordionItem
                id="item-3"
                title="Territory optimisation"
                highlight="Find and rank opportunities in global markets"
                content="Score and prioritize your target accounts frequently. Use external data to incorporate new learnings and optimise your territories."
              />
              <AccordionItem
                id="item-4"
                title="Closed Won analysis"
                highlight="Discover new factors that drive wins and losses"
                content="Find new external triggers that create meetings, opportunities, and wins. Use this data for future account prioritisation based on what makes a ideal customer for you. Iterate and optimise your ideal customer profile (ICP)."
              />
            </Accordion.Root>
            <div className="mt-12">
              <Link to="/solutions/#cta" className={teamPillClass}>
                Learn more &rarr;
              </Link>
            </div>
          </div>
          <div className="flex-1">
            <img src={RevOps1} />
          </div>
        </section>

        {/* Marketing */}

        <section
          className="container text-white my-48 flex flex-col px-4 lg:px-0 gap-24 lg:flex-row-reverse items-center"
          id="marketing"
        >
          <div className="flex-1">
            <h3 className="text-5xl leading-tight mb-16 font-semibold">
              Marketing
            </h3>

            <Accordion.Root
              type="single"
              defaultValue="item-1"
              className="divide-y divide-dark-grey/50"
            >
              <AccordionItem
                id="item-1"
                title="Account Based Marketing (ABM)"
                highlight="Detailed, customised target account and people research in one click"
                content="Identify the right accounts for ABM. Understand strategies and initiatives, stakeholders, current technology, spot trends and more in one click."
              />
              <AccordionItem
                id="item-2"
                title="Demand Generation"
                highlight="Build niche audiences using web and people data in hours, not weeks"
                content="Create precisely targeted audiences by transforming web and people data and insights into marketer-friendly dimensions. Personalise customer conversations at any size and in any channel."
              />
            </Accordion.Root>
            <div className="mt-12">
              <Link to="/solutions/#cta" className={teamPillClass}>
                Learn more &rarr;
              </Link>
            </div>
          </div>
          <div className="flex-1">
            <img src={MarketingSegmentation} className="w-full rounded-3xl" />
          </div>
        </section>

        {/* Sales */}

        <section
          className="container text-white my-48  flex flex-col lg:flex-row px-4 lg:px-0 gap-24 items-center"
          id="sales"
        >
          <div className="flex-1">
            <h3 className="text-5xl leading-tight mb-16 font-semibold">
              Sales
            </h3>

            <Accordion.Root
              type="single"
              defaultValue="item-1"
              className="divide-y divide-dark-grey/50"
            >
              <AccordionItem
                id="item-1"
                title="Target accounts"
                highlight="Build target account lists based on web and people data"
                content="Find, score and prioritise companies that fit your current ideal customer profile, using granular attributes extracted from web and people data."
              />
              <AccordionItem
                id="item-2"
                title="Timing triggers"
                highlight="Better Google Alerts"
                content="Replace noisy Google Alerts with more targeted, summarised email or CRM feed updates about key companies and people. Sync alerts with your CRM."
              />
              <AccordionItem
                id="item-3"
                title="Personalisation"
                highlight="Detailed, customised prospect research in one click"
                content="Replace repetitive Googling for people and keywords. Understand strategies and initiatives, stakeholders, current technology, and more in one click."
              />
              <AccordionItem
                id="item-4"
                title="Product-led sales"
                highlight="Convert product qualified leads with web and people triggers"
                content="Super charge your PQL model with signals your prospects are sharing on the internet. What they're saying, what they're building, who they are hiring, and more."
              />
              <AccordionItem
                id="item-5"
                title="Inbound sales"
                highlight="Prioritise and convert inbound leads by automatically scraping the web and people data"
                content="Super charge your lead scoring model with signals your prospects are sharing on the internet. What they're saying, what they're building, who they are hiring, and more."
              />
            </Accordion.Root>
            <div className="mt-12">
              <Link to="/solutions/#cta" className={teamPillClass}>
                Learn more &rarr;
              </Link>
            </div>
          </div>
          <div className="flex-1">
            <img src={SalesAlerts} className="w-full rounded-3xl" />
          </div>
        </section>

        {/* Customer Success */}

        <section
          className="container text-white my-48  flex flex-col px-4 lg:px-0 items-center gap-24 lg:flex-row-reverse"
          id="customer-success"
        >
          <div className="flex-1">
            <h3 className="text-5xl leading-tight mb-16 font-semibold">
              Customer Success
            </h3>

            <Accordion.Root
              type="single"
              defaultValue="item-1"
              className="divide-y divide-dark-grey/50"
            >
              <AccordionItem
                id="item-1"
                title="Upsell and renewals"
                highlight="Use external signals to identify upsell and renewal opportunities"
                content="Find and optimise new external triggers that complement product usage analytics. Create 2x more opportunities for upsell by aligning your product with customer goals."
              />
              <AccordionItem
                id="item-2"
                title="Reduce churn"
                highlight="Create strategic alignment with deeper customer research"
                content="Stay on top of external signals that enable proactive and personalised outreach. Create more opportunities for renewal by aligning your product with customer goals."
              />
            </Accordion.Root>
            <div className="mt-12">
              <Link to="/solutions/#cta" className={teamPillClass}>
                Learn more &rarr;
              </Link>
            </div>
          </div>
          <div className="flex-1">
            <img src={Sales3} />
          </div>
        </section>

        {/* Partnerships */}

        <section
          className="container text-white my-48 flex flex-col lg:flex-row px-4 lg:px-0 items-center gap-24"
          id="partnerships"
        >
          <div className="flex-1">
            <h3 className="text-5xl leading-tight mb-16 font-semibold">
              Partnerships
            </h3>

            <Accordion.Root
              type="single"
              defaultValue="item-1"
              className="divide-y divide-dark-grey/50"
            >
              <AccordionItem
                id="item-1"
                title="Add more partners"
                highlight="Find more partners aligned with your value proposition"
                content="Find, score and prioritise partners or potential partners that fit your current go-to-market and value proposition, using granular attributes extracted from web and people data."
              />
            </Accordion.Root>
            <div className="mt-12">
              <Link to="/solutions/#cta" className={teamPillClass}>
                Learn more &rarr;
              </Link>
            </div>
          </div>
          <div className="flex-1">
            <img src={Sales2} />
          </div>
        </section>

        {/* Operations & Enablement */}

        <section
          className="container text-white my-48  flex flex-col px-4 lg:px-0 items-center gap-24 lg:flex-row-reverse"
          id="operations-enablement"
        >
          <div className="flex-1">
            <h3 className="text-5xl leading-tight mb-16 font-semibold">
              Operations &amp; Enablement
            </h3>

            <Accordion.Root
              type="single"
              defaultValue="item-1"
              className="divide-y divide-dark-grey/50"
            >
              <AccordionItem
                id="item-1"
                title="Onboarding"
                highlight="Onboard and ramp salespeople faster with data"
                content="Tactic gives your new hires a library of previous account and prospect research they can use to onboard faster."
              />
              <AccordionItem
                id="item-2"
                title="Coaching"
                highlight="Help your sales team follow best practice. and act on the right insights"
                content="Research and plan each engagement with detailed customer research. Guide reps on messaging and approach based on granular web and people data."
              />
              <AccordionItem
                id="item-3"
                title="Enrichment"
                highlight="Enrich your CRM with the right data points continuously"
                content="Enrich your entire CRM with custom attributes, extracted from web and people data. Global and multilingual support. "
              />
              <AccordionItem
                id="item-4"
                title="Account Data"
                highlight="Account data with a wider variety of attributes and filters"
                content="Find net new account data that fit your current ideal customer profile, using granular attributes extracted from web and people data."
              />
            </Accordion.Root>
            <div className="mt-12">
              <Link to="/solutions/#cta" className={teamPillClass}>
                Learn more &rarr;
              </Link>
            </div>
          </div>
          <div className="flex-1">
            <img src={RevOps3} />
          </div>
        </section>
      </div>
      <div id="cta">
        <CTA />
      </div>
    </Layout>
  )
}

export default Platform
